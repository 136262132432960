import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";

import about from "./pages/about";
import research from "./pages/research";
import researchassistance from "./pages/researchassistance";
import headshot from "./images/kathrynholston.png";

function App() {
  return (
    <Router>
      <div className="min-h-screen min-w-full bg-cream text-outer-space">
        <div className="container mx-auto px-8 py-16 lg:px-16 pb-40 flex flex-col space-y-12">
          <div className="flex flex-col lg:flex-row lg:items-center font-sans leading-relaxed">
            <div className="lg:w-1/3 xl:w-1/4">
              <div className="mx-auto lg:ml-0 mb-6 lg:mb-0 h-48 w-48 xl:w-52 xl:h-52 rounded-full overflow-hidden">
                <img
                  className="w-full h-full object-cover"
                  src={headshot}
                  alt="Headshot"
                ></img>
              </div>
            </div>
            <div className="lg:w-2/3 xl:w-3/4">
              <h1 className="font-serif text-3xl md:text-5xl lg:text-6xl leading-tight text-center lg:text-left">
                Kathryn Holston
              </h1>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row font-sans leading-relaxed">
            {/* NAV */}
            <div className="flex justify-center lg:justify-start space-x-8 lg:space-x-0 lg:flex-col lg:space-y-2 mb-8 lg:mb-16 lg:w-1/3 xl:w-1/4 lg:pl-8">
              <div>
                <NavLink exact to="/" activeClassName="nav-link-active">
                  About
                </NavLink>
              </div>
              <div>
                <NavLink exact to="/research" activeClassName="nav-link-active">
                  Research
                </NavLink>
              </div>
              <div>
                <a
                  className="flex items-center"
                  rel="noreferrer"
                  target="_blank"
                  href="https://firebasestorage.googleapis.com/v0/b/holston-kathryn.appspot.com/o/Holston_CV.pdf?alt=media&token=46bd13b9-9f53-4053-916f-998177b8a404"
                >
                  CV
                  <svg
                    className="ml-1 mb-1"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z" />
                  </svg>
                </a>
              </div>
            </div>
            <div className="lg:w-2/3 xl:w-3/4">
              <div className="body-content max-w-2xl">
                <Switch>
                  <Route exact path="/" component={about} />
                  <Route exact path="/research" component={research} />
                  <Route
                    exact
                    path="/research-assistance"
                    component={researchassistance}
                  />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
